@import '../../scss/shared/componentsImport';

.FormToggle {
	position: relative;
	display: inline-block;
	width: 36px;
	height: 20px;

	.slider {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		cursor: pointer;
		background-color: $skySecondary;
		border-radius: 20px;
		transition: 0.5s cubic-bezier(0.23, 1, 0.32, 1);

		&:before {
			content: '';
			display: block;
			position: absolute;
			width: 16px;
			height: 16px;
			top: 2px;
			left: 2px;
			border-radius: 16px;
			background-color: #fff;
			transition: 0.5s cubic-bezier(0.23, 1, 0.32, 1);
		}

		&:hover {
			background-color: $skyPrimary;
		}

		svg {
			position: absolute;
			opacity: 0;
			top: 2px;
			left: 3px;
			transform: scale(0.5) translate3d(0, 0, 0);
			z-index: 10;
			transition: 0.5s cubic-bezier(0.23, 1, 0.32, 1);
		}
	}

	input {
		width: 0;
		height: 0;
		opacity: 0;

		&:checked + .slider {
			background-color: $skyPrimary;

			&:before {
				transform: translateX(15px);
			}

			svg {
				opacity: 1;
				transform: scale(0.5) translate3d(28px, 0, 0);
			}
		}
	}

	.theme--earth & {
		.slider {
			background-color: $earthSecondary;

			&:hover {
				background-color: $earthPrimary;
			}
		}

		input:checked + .slider {
			background-color: $earthPrimary;
		}
	}
}
