@each $key, $val in $colors {
	.bg-#{$key} {
		background-color: $val !important;
	}

	.color-#{$key} {
		color: $val;

		svg {
			path {
				fill: $val !important;
			}
		}
	}
}
