@import '../../scss/shared/componentsImport';

.Educators {
	&__grid {
		@include bp() {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 18px;
		}

		.ProfileCard + .ProfileCard {
			margin-top: 18px;

			@include bp() {
				margin-top: 0;
			}
		}
	}
}
