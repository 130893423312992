@import '../../scss/shared/componentsImport';

.SortableLessonCard {
	padding: 15px;
	position: relative;
	border-radius: 12px;
	box-shadow: 0 3px 36px -10px rgb(0 0 0 / 0.22);
	transition: 0.2s ease-in-out;
	background-color: #fff;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	cursor: pointer !important;

	> svg {
		margin-right: 20px;
	}

	&__info {
		h5 {
			margin-bottom: 0;
			font-weight: 600;
		}
		h6 {
			@include font-size(12px);
		}
	}

	&__status {
		margin-left: auto;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		@include font-size(12px);

		.icon {
			margin-left: 5px;
			display: inline-block;
			width: 16px;
			height: 16px;
			background: map-get($colors, 'gray');
			border: 1px solid map-get($colors, 'gray');
			@include border-radius(50%);

			svg {
				transform: scale(0.6) translate(-2px, -1px);

				path {
					fill: map-get($colors, 'white');
					stroke: map-get($colors, 'white');
				}
			}

			&.active {
				background: map-get($colors, 'white');
				border-color: map-get($colors, 'green');

				svg {
					path {
						stroke: map-get($colors, 'green');
					}
				}
			}

			&.complete {
				background: map-get($colors, 'green');
				border: 1px solid transparent;
			}
		}
	}

	&__button{
		display: none;
		margin-left: auto;
		flex-flow: row nowrap;
		align-items: center;
	}

	// &__arrows{
	// 	display: none;
	// 	margin-left: auto;
	// 	flex-flow: row nowrap;
	// 	align-items: center;
	// }

	// &__upArrow{
	// 	&:hover, &:focus{
	// 		filter: invert(.5);
	// 		cursor: pointer;
	// 	}
	// }
	// &__downArrow{
	// 	margin-left: 15px;
	// 	&:hover, &:focus{
	// 		filter: invert(.5);
	// 		cursor: pointer;
	// 	}
	// }

	&:hover, &:focus, &:focus-within{
		.SortableLessonCard__status{
			display: none;
		}
		// .SortableLessonCard__arrows,
		.SortableLessonCard__button{
			display: flex;
			line-height: 0.7375rem;
		}
	}
}
