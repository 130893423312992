@mixin container($maxWidth: $xl) {
	max-width: $maxWidth;
	margin: 0 auto;
	padding: 0 20px;
	box-sizing: border-box;
}

@mixin bp($size: $m) {
	@media (min-width: $size) {
		@content;
	}
}

@mixin bpLandscape($size: $m) {
	@media (max-width: $size) and (orientation: landscape) {
		@content;
	}
}

@mixin bpmax($size: $m) {
	@media (max-width: $size - 1px) {
		@content;
	}
}

@mixin retina() {
	@media (min--moz-device-pixel-ratio: 1.3),
		(-o-min-device-pixel-ratio: 2.6/2),
		(-webkit-min-device-pixel-ratio: 1.3),
		(min-device-pixel-ratio: 1.3),
		(min-resolution: 1.3dppx) {
		@content;
	}
}

@function calculateRem($size) {
	$remSize: math.div($size, 16px);
	@return $remSize * 1rem;
}

@mixin font-size($size) {
	font-size: $size;
	font-size: calculateRem($size);
}

@mixin transition($args...) {
	-webkit-transition: $args;
	-moz-transition: $args;
	-ms-transition: $args;
	-o-transition: $args;
	transition: $args;
}

@mixin border-radius($radius) {
	border-radius: $radius;
}

@mixin flexgrid($gap: $gap) {
	@include bp() {
		display: flex;
		flex-flow: row wrap;
		margin: calc(-1 * $gap) 0 0 calc(-1 * $gap);
	}

	> * {
		margin-bottom: $gap;

		@include bp() {
			margin-bottom: 0;
			padding: $gap 0 0 $gap;
			flex: 1;
		}
	}
}
