@import '../../scss/shared/componentsImport';

.ColumnCard {
	position: relative;
	overflow: hidden;
	background: map-get($colors, 'white');
	border-radius: 12px;
	box-shadow: 0 3px 36px -10px rgb(0 0 0 / 0.22);

	@include bp() {
		display: flex;
		flex-flow: row nowrap;

		> * {
			flex: 1;
		}
	}

	& + & {
		margin-top: 20px;
	}

	&__icon {
		position: absolute;
		top: 15px;
		right: 15px;
		z-index: 10;

		@include bp() {
			top: 32px;
			right: 32px;
		}
	}

	&__image {
		@include bp() {
			max-width: 270px;
		}

		&--padded {
			padding: 25px;

			@include bp() {
				padding-right: 0;
			}

			.ColumnCard__image__inner {
				background-size: contain;
				background-position: center;
			}
		}

		&__inner {
			background-repeat: no-repeat;
			background-position: top center;
			background-size: cover;
			height: 100%;

			&:after {
				content: '';
				display: block;
				padding-bottom: 60%;

				@include bp() {
					padding-bottom: 100%;
				}
			}
		}

		.DefaultPortrait {
			height: 100%;
		}
	}

	& > &__col {
		padding: 30px;

		&:nth-of-type(3) {
			display: none;

			@include bp() {
				display: unset;
				max-width: 31%;
			}

			h4 {
				font-weight: 500;
				color: map-get($colors, 'black');
			}

			.btn--secondary {
				padding: 13px 10px;
			}
		}

		@include bp() {
			& + .ColumnCard__col {
				border-left: 1px solid $skyTertiary;
			}
		}
	}

	.wysiwyg {
		h4 {
			font-weight: 600;
			margin-bottom: 12px;
		}

		ul {
			margin-bottom: 20px;

			li {
				margin-bottom: 10px;
			}
		}
	}

	.TableOfContents {
		ul {
			margin-bottom: 30px;
			li {
				a.link .link__title {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
		}
	}

	.profile {
		.profile__item {
			display: flex;
			align-items: center;

			+ .profile__item {
				margin-top: 12px;
			}

			svg {
				margin-right: 10px;
			}

			a {
				margin-bottom: 0;
			}
		}
	}

	.profile--he {
		.profile__title {
			font-weight: 600;
			margin-bottom: 20px;
		}

		.portrait,
		.DefaultPortrait {
			width: 60px;
			height: 60px;
			@include border-radius(50%);
			margin-right: 15px;
		}
	}
}
