@import '../../scss/shared/componentsImport';

.Dropdown {
	position: absolute;
	right: 0;
	bottom: -11px;
	width: 300px;
	max-width: 96vw;
	transform: translate(21.5%, 100%);
	max-width: 86vw;
	transform: translate(14%, 100%);
	z-index: 100;
	display: none;

	&--shown {
		display: block;
	}

	@include bp($s) {
		max-width: 86vw;
		transform: translate(14%, 100%);
	}

	&:before {
		position: absolute;
		right: 0;
		transform: translate(-25vw, -7px) rotate(45deg);
		content: '';
		display: block;
		width: 12px;
		height: 12px;
		background: #ffffff;
		z-index: 1;
		transform: translate(-42px, -7px) rotate(45deg);

		@include bp($s) {
			transform: translate(-42px, -6px) rotate(45deg);
		}
	}

	.card--dropdown {
		margin: 0;
		height: auto;
		min-height: unset;
		overflow: hidden;
	}

	a,
	.btn--link {
		color: $skyPrimary;
		@include font-size(15px);
		background: none !important;
		@include border-radius(0 !important);
		border: none !important;
		border-bottom: 1px solid $skyTertiary !important;
		padding: 15px 30px !important;
		text-align: left !important;
		display: block;
	}
}
