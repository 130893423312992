@import '../../scss/shared/componentsImport';

.MyClients {
	&__admin-toggle {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		font-weight: bold;
		color: $skyPrimary;
		margin-bottom: 20px;

		> span {
			margin-right: 15px;
		}
	}

	&__grid {
		@include bp() {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 18px;
		}

		.ProfileCard + .ProfileCard {
			margin-top: 18px;

			@include bp() {
				margin-top: 0;
			}
		}
	}
}
