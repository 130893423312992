@import '../../scss/shared/componentsImport';

.Layout {
	position: relative;

	&__notifications-list {
		position: fixed;
		bottom: 0;
		width: 100vw;
		z-index: 1000;

		.Layout__notification {
			padding: 12px 20px;
			position: relative;
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			justify-content: center;

			&--offline {
				background-color: rgba(map-get($colors, 'orange'), 0.9);
				padding-right: 80px;
				padding-left: 75px;
			}

			&--viewAs {
				background-color: rgba($skyPrimary, 0.9);
				color: #fff;
				padding-right: 80px;
				padding-left: 75px;

				> .Button--with-icon {
					color: #fff;
					position: absolute;
					top: 50%;
					right: 20px;
					transform: translate3d(0, -50%, 0);

					line,
					polyline,
					path {
						fill: #fff;
					}
				}
			}

			> * {
				margin-bottom: 0;
			}

			&__text {
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
				width: 100%;

				> svg {
					margin-right: 5px;
				}

				@include bp($m) {
					width: auto;
				}
			}

			&__sync-notice {
				display: block;
				width: 100%;

				@include bp($m) {
					width: auto;
					position: absolute;
					top: 50%;
					right: 20px;
					transform: translate3d(0, -50%, 0);
				}
			}
		}
	}
}
