@import '../../scss/shared/componentsImport';

.Container {
}

.container {
	width: 100%;
	min-height: 20px;
	margin-bottom: 20px;

	@include bp($m) {
		&--col1 {
			width: 32%;
			margin-left: 3%;
			float: left;
		}

		&--col2 {
			width: 65%;
			margin-left: 1.5%;
			float: left;
		}

		&--halfwidth {
			width: 47%;
			margin-left: 6%;
			float: left;
		}

		&--row,
		&--fullwidth {
			float: left;

			div:first-child {
				margin-left: 0;
			}

			&:after {
				content: '';
				clear: both;
			}
		}

		&--grid {
			> div {
				margin-left: 2%;
				margin-bottom: 5px;
			}
			div:nth-child(3n + 1) {
				margin-left: 0;
				clear: both;
			}
		}
	}
}
