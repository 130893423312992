@import '../../scss/shared/componentsImport';

.Modal {
	background-color: rgba(#fff, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1001;

	.Modal__close-button {
		position: absolute;
		top: 20px;
		right: 23px;
		padding: 5px;
		border-radius: 100%;

		> .Button__icon {
			margin: 0;

			svg path {
				fill: #fff;
			}
		}
	}

	&__content {
		width: 90%;
		max-height: 90%;
		max-width: 600px;
		padding: 30px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
		background-color: #fff;
		border-radius: 12px;
		overflow: hidden;
		box-shadow: 0 3px 45px rgba(#000, 0.22);
	}
}
