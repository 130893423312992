@import '../../scss/shared/componentsImport';

.MyProfile {
	.ColumnCard {
		margin-bottom: 40px;
	}

	.h5 {
		font-weight: bold;
		margin-bottom: 20px;
	}

	&__progress-grid {
		margin-bottom: 40px;

		@include bp() {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 20px;
		}

		.MiniProgressCard + .MiniProgressCard {
			margin-top: 20px;

			@include bp() {
				margin-top: 0;
			}
		}
	}
}
