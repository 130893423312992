@import '../../scss/shared/componentsImport';

.NewUser {
	&__errors {
		color: map-get($colors, 'red');
		margin-bottom: 20px;

		h5 {
			font-weight: bold;
		}

		.errors > li:before {
			background-color: map-get($colors, 'red');
		}
	}

	&__card {
		padding: 20px;
		position: relative;
		border-radius: 12px;
		box-shadow: 0 3px 36px -10px rgb(0 0 0 / 0.22);
		transition: box-shadow 0.2s ease-in-out;
		background-color: #fff;

		&:hover {
			box-shadow: 0 15px 36px 0px rgb(26 26 26 / 0.15);
		}

		@include bp() {
			display: flex;
			flex-flow: row nowrap;
		}

		.ImageUpload {
			@include bp() {
				width: 30%;
			}
		}
	}

	&__profile-form {
		@include bp() {
			flex: 1;
			margin-left: 20px;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-auto-rows: minmax(min-content, max-content);
			gap: 20px;
		}

		.form-field {
			min-height: 0;
		}

		.FormInput,
		.FormSelect {
			margin-bottom: 0;
			max-width: none;

			input,
			select {
				margin-bottom: 0;
			}
		}

		> * + * {
			margin-top: 20px;

			@include bp() {
				margin-top: 0;
			}
		}
	}
}
