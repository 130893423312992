@import '../../scss/shared/componentsImport';

.EditClient {
	&__card {
		padding: 20px;
		position: relative;
		border-radius: 12px;
		box-shadow: 0 3px 36px -10px rgb(0 0 0 / 0.22);
		transition: box-shadow 0.2s ease-in-out;
		background-color: #fff;

		&:hover {
			box-shadow: 0 15px 36px 0px rgb(26 26 26 / 0.15);
		}

		&--bordered-list {
			padding: 0;

			> li {
				padding: 20px;

				+ li {
					border-top: 1px solid $skySecondary;
				}

				> header {
					display: flex;
					flex-flow: row nowrap;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 10px;

					h5 {
						color: $skyPrimary;
						margin-bottom: 0;
					}
				}

				> *:last-child {
					margin-bottom: 0;
				}
			}
		}

		h5 {
			font-weight: bold;
			margin-bottom: 12px;
		}
	}

	&__main {
		@include bp() {
			display: flex;
			flex-flow: row nowrap;
		}

		.ImageUpload {
			@include bp() {
				width: 30%;
			}
		}

		.profile-form {
			width: 100%;

			@include bp() {
				display: flex;
				flex-flow: column nowrap;
				justify-content: space-between;
			}

			form {
				@include bp() {
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					grid-auto-rows: minmax(min-content, max-content);
					gap: 18px;
					margin-left: 20px;
				}

				.form-field .FormInput,
				.form-field .FormInput input {
					margin: 0;
				}

				.form-field + .form-field {
					margin: 0;
					margin-top: 18px;

					@include bp() {
						margin-top: 0;
					}
				}
			}
		}
	}

	&__secondary {
		margin: 30px 0;

		@include bp() {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 18px;
		}

		.FormSelect {
			max-width: none;
		}
	}
}
