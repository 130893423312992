@import '../../scss/shared/componentsImport';

.Register {
	min-height: 100vh;
	display: flex;
	flex-flow: column nowrap;

	@include bp() {
		height: 100vh;
		flex-flow: row nowrap;

		> * {
			flex: 0 0 50%;
		}
	}

	&__image {
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;

		@include bp() {
			flex-grow: 1;
		}

		&:after {
			content: '';
			display: block;
			padding-bottom: 56.25%;

			@include bp() {
				display: none;
			}
		}
	}

	&__form {
		overflow: hidden;
		flex-grow: 1;

		@include bp() {
			max-width: 654px;
		}

		.progress {
			display: flex;
			flex-flow: row nowrap;
			margin: 25px;

			@include bp() {
				margin: 28px 90px 0;
			}

			&__step {
				flex: 1;
				height: 2px;
				background-color: #e4e4e4;
				position: relative;

				&:after {
					content: '';
					display: block;
					width: 10px;
					height: 10px;
					border-radius: 10px;
					background-color: inherit;
					position: absolute;
					top: 50%;
					right: 0;
					transform: translate3d(50%, -50%, 0);
					z-index: 10;
				}

				&.completed {
					background-color: #c2d3a1;

					&:after {
						background-color: #009774;
					}
				}
			}
		}

		.logo {
			margin-bottom: 50px;
		}

		.screens {
			position: relative;
			height: 50vh;
			width: 300%;
			transition: 0.25s cubic-bezier(0.23, 1, 0.32, 1);

			@include bp() {
				height: 100%;
			}

			> li {
				padding: 25px;
				width: 33.3%;
				height: 100%;
				position: absolute;
				display: flex;
				flex-flow: column nowrap;
				justify-content: center;

				&:nth-child(2) {
					transform: translateX(100%);
				}

				&:nth-child(3) {
					transform: translateX(200%);
				}

				@include bp() {
					padding: 28px 90px 0;
				}
			}
		}
	}
}
