@import '../../scss/shared/componentsImport';

.TableOfContents {
	padding: 20px 30px;
	background-color: #fff;
	border-radius: 12px;
	box-shadow: 0 3px 36px -10px rgb(0 0 0 / 0.22);
	@include transition(box-shadow 0.2s ease-in-out);

	&:hover {
		box-shadow: 0 15px 36px 0px rgb(26 26 26 / 0.15);
	}

	h5 {
		font-weight: bold;
		margin-bottom: 20px;
	}

	&__list {
		list-style: none;
		padding: 0;
		margin-bottom: 0;

		li {
			padding: 8px 0;
			border-bottom: 1px solid $skyTertiary;

			&:last-of-type {
				border: none;
			}

			a {
				display: flex;
				flex-flow: row;
				align-items: center;
				margin: 0;
				border: none;
				font-weight: 400;
				color: $skyPrimary;
			}
		}

		.link {
			&__icon {
				display: inline-block;
				width: 16px;
				height: 16px;
				background: map-get($colors, 'gray');
				border: 1px solid map-get($colors, 'gray');
				@include border-radius(50%);
				margin: 0 10px 0 0;

				svg {
					transform: scale(0.6) translate(-2px, -1px);

					path {
						fill: map-get($colors, 'white');
						stroke: map-get($colors, 'white');
					}
				}
			}

			&.viewing {
				font-weight: 600;

				.link__icon {
					background: map-get($colors, 'white');
					border-color: map-get($colors, 'green');

					svg {
						path {
							stroke: map-get($colors, 'green');
						}
					}
				}
			}

			&.viewed {
				.link__icon {
					background: map-get($colors, 'green');
					border: 1px solid transparent;
				}
			}

			&.answered {
				.link__icon {
					background: map-get($colors, 'black');
					border-color: map-get($colors, 'black');
				}
			}
		}

		// &.col2 {
		// 	display: flex;
		// 	flex-flow: row;
		// 	flex-wrap: wrap;

		// 	li {
		// 		width: 50%;
		// 		display: inline-block;

		// 		&:nth-last-of-type(-n + 2):not(:nth-child(even)) {
		// 			border: none;
		// 		}
		// 	}
		// }
	}
}
