@import '../../scss/shared/componentsImport';

.Module {
	.ColumnCard {
		margin-bottom: 50px;
	}

	&__tabs {
		margin-bottom: 30px;
	}

	&__lesson-grid {
		margin-bottom: 40px;

		.LessonCard {
			padding: 20px;
			position: relative;
			border-radius: 12px;
			box-shadow: 0 3px 36px -10px rgb(0 0 0 / 0.22);
			transition: box-shadow 0.2s ease-in-out;
			background-color: #fff;

			&:hover {
				box-shadow: 0 15px 36px 0px rgb(26 26 26 / 0.15);
			}

			&__image {
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				max-width: 230px;
				margin: 0 auto 20px;

				&:after {
					content: '';
					display: block;
					padding-bottom: 100%;
				}
			}

			&__content {
				// @include bp() {
				// 	display: flex;
				// 	flex-flow: column nowrap;
				// }

				.Button {
					display: block;

					@include bp() {
						margin-top: auto;
					}
				}
			}
		}

		@include bp() {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 20px;
		}

		> * + * {
			margin-top: 20px;

			@include bp() {
				margin-top: 0;
			}
		}
	}

	&__offline-notification {
		background-color: map-get($colors, 'orange');
		text-align: center;
		padding: 12px 0;
		margin-bottom: 40px;
		width: 100%;
		opacity: 0.9;
		@include border-radius(12px);
	}

	@include bp($m) {
		.container {
			display: inline-flex;
			&--row,
			&--fullwidth {
				display: flex;
				flex-wrap: wrap;
				> div {
					&:nth-child(3n + 1) {
						margin-left: 0;
					}
				}
			}

			.Card {
				display: flex;
			}
			&--col1 {
				width: 31.33%;

				.card {
					margin: 0 0 15px;

					.wrapper {
						padding: 20px;
					}

					img {
						margin-bottom: 20px;
					}

					.btn {
						max-width: unset;
					}
				}
			}
		}
	}

	.ColumnCard__col {
		h4 {
			margin-bottom: 20px;
		}
		ul {
			list-style: disc outside;
			margin-left: 20px;
		}
	}
}
