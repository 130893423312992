@import '../../scss/shared/componentsImport';

.Icon {
}

svg {
	&.primary {
		path {
			fill: $skyPrimary;
		}
	}
	&.secondary {
		path {
			fill: $skySecondary;
		}
	}
	&.completed,
	&.visited,
	&.green {
		path {
			fill: map-get($colors, 'green');
		}
	}
}
