html,
body {
	box-sizing: content-box;
	background-color: map-get($colors, 'white');
	color: map-get($colors, 'black');
}

body {
	padding-bottom: 40px;
	min-height: 0;
}

ul,
ol {
	list-style: none;
	padding: 0;
	margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	margin: 0;
}

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
	color: $skyPrimary;
	margin-bottom: 20px;
}

a {
	color: $skyPrimary;
	// margin-bottom: 20px;
	cursor: pointer;
}

p {
	margin-bottom: 20px;
}

.wrapper {
	padding: 0 15px;
	width: 100%;
	margin: auto;

	@include bp($s) {
		padding: 0 30px;
		width: 90%;
		max-width: 1300px;
	}
}

.bg {
	background-color: $skyTertiary;

	&--dark {
		background-color: $skyPrimary !important;

		svg {
			path {
				fill: map-get($colors, 'white') !important;
			}
		}
	}

	&--light {
		background-color: $skySecondary;
	}
}

.he {
	&__title {
		font-weight: 500;
		display: flex;
		align-items: center;

		svg {
			margin-right: 20px;
		}
	}
}

.block {
	&__title {
		font-weight: 700;
		margin: 0 0 10px;
	}

	&__list {
		list-style: disc;
		padding-left: 20px;

		li {
			margin-bottom: 10px;
		}
	}

	&--icon {
		position: relative;
		padding: 30px 0 20px 40px;

		@include bp($s) {
			padding: 30px 0 20px 60px;
		}

		&.participant__block {
			padding-top: 15px;
		}

		.icon {
			position: absolute;
			top: 15px;
			left: 0;
			display: block;
			width: 36px;
			height: 36px;
			margin: 0;

			svg {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate3d(-50%, -50%, 0);
			}
		}

		.block__title {
			margin-top: -10px;
		}
	}
}

ul.styled li {
	position: relative;

	&:before {
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: 4px;
		height: 4px;
		background-color: var(--theme-color);
		border-radius: 4px;
		position: relative;
		margin-right: 14px;
		transform: translateY(-1px);
	}

	> ul,
	> ol {
		margin-left: 25px;
		margin-top: 4px;
		margin-bottom: 0;
	}
}
