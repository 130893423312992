@import '../../scss/shared/componentsImport';

.Login {
	@include bp() {
		display: flex;
		flex-flow: row nowrap;
		height: 100vh;
	}

	&__content,
	&__image {
		flex: 1;
	}

	&__image {
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		background-color: map-get($colors, 'bleachedMaroon');
		margin: 100px 20px 0px;
		@include border-radius(12px);

		&:after {
			content: '';
			display: block;
			padding-bottom: 56.25%;
		}

		@include bp() {
			margin: 0;
			height: 100%;
			order: 2;
			@include border-radius(0);

			&:after {
				display: none;
			}
		}
	}

	&__content {
		padding: 20px;
		text-align: center;

		@include bp() {
			padding: 100px;
			text-align: left;
		}

		.logo {
			position: absolute;
			top: 20px;
			left: 0;
			width: 100%;
			text-align: center;

			@include bp() {
				position: relative;
				top: unset;
				left: unset;
				width: unset;
				text-align: left;
			}
		}

		.intro {
			margin-bottom: 20px;
		}

		.btn--link {
			display: inline-block;
		}

		.error {
			margin-top: 20px;
		}
	}
}
