@import '../../scss/shared/componentsImport';

.Button {
	border: 0;
	position: relative;
	padding: 10px;
	color: map-get($colors, 'white');
	background-color: var(--theme-color);
	border: 2px solid var(--theme-color);
	border-radius: 8px;
	transition: 0.25s ease-in-out;
	font-weight: 500;
	text-align: center;
	@include font-size(13px);

	@include bp() {
		padding: 13px 25px;
		@include font-size(15px);
	}

	&:hover {
		cursor: pointer;
		background-color: var(--theme-secondary);
		color: var(--theme-color);
	}

	&--danger {
		background-color: darken(map-get($colors, 'red'), 10%) !important;
		border-color: darken(map-get($colors, 'red'), 10%) !important;
		color: #fff !important;
		font-weight: bold !important;

		&:hover {
			background-color: darken(map-get($colors, 'red'), 15%) !important;
			border-color: darken(map-get($colors, 'red'), 15%) !important;
		}
	}

	&[disabled] {
		color: map-get($colors, 'darkGray') !important;
		background-color: map-get($colors, 'gray') !important;
		border-color: map-get($colors, 'gray') !important;
		cursor: not-allowed !important;
	}

	&--secondary {
		background-color: transparent;
		color: var(--theme-color);
	}

	&--icon {
		width: 26px;
		height: 26px;
		border-radius: 26px;
		padding: 0;

		svg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: scale(0.7) translate3d(-14px, -14px, 0);

			path {
				fill: #fff;
			}
		}

		&:hover {
			background-color: var(--theme-color);
		}
	}

	&--with-icon {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: center;

		> .Button__icon {
			margin-left: 5px;
		}
	}

	&--link {
		padding: 0;
		border: 0;
		border-radius: 0;
		font-weight: bold;
		color: var(--theme-color);
		background-color: transparent;

		&:hover {
			background-color: transparent;
		}
	}

	&__icon {
		display: inline-flex;
	}
}
