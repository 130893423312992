@import '../../scss/shared/componentsImport';

.Breadcrumbs {
	margin-bottom: 20px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;

	> li {
		&.last {
			opacity: 0.5;

			svg {
				display: none;
			}
		}

		svg {
			margin-left: 14px;
		}

		+ li {
			margin-left: 14px;
		}

		> a {
			color: map-get($colors, 'black');

			&:hover {
				border-bottom-color: transparent;
			}
		}
	}
}
