@import '../../scss/shared/componentsImport';

.ProfileCard {
	box-shadow: 0 3px 36px -10px rgb(0 0 0 / 0.22);
	background-color: #fff;
	border-radius: 12px;
	padding: 30px 17px;

	@include bp() {
		display: flex;
		flex-flow: column nowrap;

		&__info {
			flex: 1;
		}
	}

	&--abridged {
		padding: 20px;

		.ProfileCard__info {
			flex-grow: 0;
			margin-bottom: 0;
		}

		.ProfileCard__header {
			margin-bottom: 10px;
		}
	}

	&--cardless {
		box-shadow: none;
		background-color: transparent;
		border-radius: 0;
		padding: 0;
	}

	&__title {
		margin-bottom: 10px;
		font-weight: bold;
	}

	&__header {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		margin-bottom: 20px;

		.DefaultPortrait,
		img {
			max-width: 60px;
			margin-right: 12px;

			&.big {
				max-width: 100px;
			}
		}

		img {
			border-radius: 100%;
		}

		.name {
			font-weight: bold;
		}
	}

	&__info {
		margin-bottom: 30px;

		li {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;

			> svg {
				transform: scale(0.8);
				margin-right: 5px;
			}

			+ li {
				margin-top: 10px;
			}
		}
	}

	.btn {
		margin: 0;
		width: 100%;
		max-width: none;
	}
}
