.card {
	display: block;
	position: relative;
	background: map-get($colors, 'white');
	border-radius: 12px;
	width: 100%;
	min-height: 176px;
	margin: 0 20px 20px 0;
	box-shadow: 0 3px 36px -10px rgb(0 0 0 / 0.22);
	@include transition(box-shadow 0.2s ease-in-out);

	&:hover {
		box-shadow: 0 15px 36px 0px rgb(26 26 26 / 0.15);
	}

	&.shaded {
		background-color: $skyTertiary;
		box-shadow: 0 3px 36px -10px rgb(0 0 0 / 0);
	}

	&--col1 {
		.btn {
			width: 100%;
		}
	}

	&--col2 {
	}

	&--col3 {
	}

	&--image {
		overflow: hidden;
		&:before {
			content: '';
			display: block;
			padding-bottom: 100%;
		}

		img {
			position: absolute; /* Take your picture out of the flow */
			top: 0;
			bottom: 0;
			left: 0;
			right: 0; /* Make the picture taking the size of it's parent */
			width: 100%; /* This if for the object-fit */
			height: 100%; /* This if for the object-fit */
			object-fit: cover; /* Equivalent of the background-size: cover; of a background-image */
			object-position: center;
		}
	}

	.wrapper {
		width: 100%;
		padding: 20px;

		@include bp($s) {
			padding: 30px;
		}
	}

	&__category {
		@include font-size(13px);
		font-weight: 400;
	}

	&__title {
		@include font-size(15px);
		font-weight: 700;
	}

	.content-type-icon {
		position: absolute;
		top: 20px;
		right: 20px;

		@include bp($s) {
			top: 30px;
			right: 30px;
		}

		svg {
			path {
				fill: $skySecondary;
			}
		}
	}

	button {
		width: 100%;

		@include bp($s) {
			width: unset;
		}
	}

	ul {
		list-style-type: disc;
	}

	&.mini {
		min-height: 90px;
		.wrapper {
			padding: 15px;
		}

		.card__title {
			color: map-get($colors, 'black');
		}
	}

	.cta {
		margin-bottom: 0 !important;
	}

	&.overview {
		.overview__item {
			display: flex;
			align-items: center;
			margin-bottom: 15px;

			svg {
				width: 20px;
				height: 20px;
				margin-right: 10px;
			}

			&.achievement--new {
				font-weight: 700;

				svg {
					path {
						fill: map-get($colors, 'green');
					}
				}
			}
		}

		hr {
			opacity: 0.6;
			border-top: none;
			margin-bottom: 15px;
		}
	}

	&.profile {
		.wrapper {
			padding: 30px 20px 20px;
		}
		.profile__item {
			display: flex;
			align-items: center;
			margin-bottom: 0px;

			> svg {
				width: 12px;
				height: 12px;
				margin-right: 10px;
			}
		}

		.profile__name {
			font-weight: 700;
		}

		.profile__pic {
			width: 60px;
			height: 60px;
			@include border-radius(50%);
			margin-right: 15px;
			margin-bottom: 10px;
		}

		&.client {
			.profile__item {
				margin-bottom: 14px;

				> svg {
					width: 20px;
					height: 20px;
				}

				a {
					margin-bottom: 0;
				}
			}

			.btn {
				display: block;
				margin: 30px auto 0;
				max-width: unset;
			}
		}
	}

	&.progress {
		.progress__item {
			display: flex;
			align-items: center;
			margin-bottom: 15px;
			position: relative;

			> *:first-child:not(.RangeSlider) {
				width: 64%;
			}

			> *:nth-child(2) {
				width: 36%;
			}

			.status {
				display: flex;
				align-items: center;
				line-height: 1;

				&__icon {
					display: inline-block;
					width: 16px;
					height: 16px;
					background: map-get($colors, 'gray');
					border: 1px solid map-get($colors, 'gray');
					@include border-radius(50%);
					margin: 0 10px 0 0;

					svg {
						transform: scale(0.6) translate(-2px, -1px);

						path {
							fill: map-get($colors, 'white');
							stroke: map-get($colors, 'white');
						}
					}
				}

				&.viewing,
				&.active {
					font-weight: 500;
					.link__icon {
						background: map-get($colors, 'white');
						border-color: map-get($colors, 'green');

						svg {
							path {
								stroke: map-get($colors, 'green');
							}
						}
					}
				}

				&.viewed,
				&.complete {
					.status__icon {
						background: map-get($colors, 'green');
						border: 1px solid transparent;
					}
				}
			}

			.time-ago {
				text-align: right;
				@include font-size(11px);
			}

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		.item {
			position: relative;
			&__details {
				margin-bottom: 0;
			}
			&__category {
				@include font-size(13px);
				font-weight: 400;
			}

			&__title {
				@include font-size(15px);
				font-weight: 700;
				margin-bottom: 0;
			}

			&__type--icon {
				position: absolute;
				bottom: 0px;
				right: 0px;
			}
		}

		.btn {
			margin: 20px 0 0;
		}

		&.client {
			.progress__item {
				> *:first-child:not(.RangeSlider) {
					width: 100%;
					line-height: 1.5;
				}

				> *:nth-child(2) {
					width: 36%;
				}

				.status {
					img {
						@include border-radius(50%);
						margin-right: 10px;
					}

					&__icon {
						position: absolute;
						top: 0;
						left: 50px;
					}
				}

				&.progress__item.fullwidth.border-top {
					padding: 15px 15px;
					box-sizing: content-box;
					width: 100%;
					transform: translate(-15px, 15px);
					border-top: 1px solid $skyTertiary;
					margin-top: -15px;

					button.a {
						border: none !important;
					}
				}
			}
		}
	}

	&.fullwidth {
		padding: 0;

		> .wrapper {
			padding: 0;
		}
	}
}
