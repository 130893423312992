@import '../../scss/shared/componentsImport';

.Notifications {
	position: absolute;
	right: 0;
	bottom: -11px;
	width: 400px;
	max-width: 96vw;
	transform: translate(21.5%, 100%);
	max-width: 86vw;
	transform: translate(14%, 100%);
	z-index: 100;

	@include bp($s) {
		max-width: 86vw;
		transform: translate(14%, 100%);
	}

	&__offline-notification {
		background-color: map-get($colors, 'orange');
		text-align: center;
		padding: 12px 0;
		margin: 0;
		width: 100%;
		position: relative;
	}

	.card--notifications {
		margin: 0;
		height: auto;
		min-height: unset;
		overflow: hidden;
	}

	.load-more {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.btn--load-more {
		width: 100%;
		margin: 0;
		color: $skyPrimary;
		background-color: map-get($colors, 'white');
		border: none;
		border-radius: unset;

		&:hover {
			color: $skyPrimary;
			background-color: map-get($colors, 'white');

			svg {
				margin-left: 10px;

				path {
					fill: map-get($colors, 'green');
				}
			}
		}

		svg {
			margin-left: 10px;

			path {
				fill: map-get($colors, 'green');
			}
		}
	}

	&:before {
		position: absolute;
		right: 0;
		transform: translate(-25vw, -7px) rotate(45deg);
		content: '';
		display: block;
		width: 12px;
		height: 12px;
		background: #ffffff;
		z-index: 1;
		transform: translate(-72px, -7px) rotate(45deg);

		@include bp($s) {
			transform: translate(-72px, -6px) rotate(45deg);
		}
	}

	&.offline {
		&:before {
			background-color: map-get($colors, 'orange');
		}
	}
}
