@import '../../scss/shared/componentsImport';

.CardTwoColumn {
	.card {
		display: flex;
		overflow: hidden;
		flex-flow: column;

		@include bp($s) {
			flex-flow: row;
		}

		.col {
			&--primary {
				@include bp($s) {
					width: 60%;

					.wrapper {
						padding-right: 60px;
					}
				}

				h4 {
					font-weight: 600;
					margin-bottom: 12px;
				}

				ul {
					margin-bottom: 20px;

					li {
						margin-bottom: 10px;
					}
				}

				button {
					// padding: 13px 50px;
				}
			}
			&--secondary {
				display: flex;
				align-items: center;
				justify-content: center;

				@include bp($s) {
					width: 40%;
				}

				img {
					object-fit: cover;
				}

				svg {
					// transform: scale(5);
					display: none;

					@include bp($s) {
						display: block;
					}

					path {
						fill: $skyPrimary;
					}
				}
			}
		}
	}
}
