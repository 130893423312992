@import '../../scss/shared/componentsImport';

.RangeSlider {
	width: 100%;
	display: flex;

	.slider {
		position: relative;
		display: inline-block;
		margin: -4px 6px 0 0;
		pointer-events: none;
		width: 100%;
	}

	/* Label ------------------- */

	.slider-label {
		position: absolute;
		z-index: 1;
		top: 4px;
		font-size: 11px;
		color: map-get($colors, 'green');
		pointer-events: none;
	}

	.slider-label.isRight {
		right: 50%;
		margin-right: -10px;
		left: auto;
		transform: translateX(10px);
	}
	.slider-label.isLeft {
		right: auto;
		margin-left: -10px;
		left: 50%;
		transform: translate(-10px);
	}

	.slider:active .slider-label {
		color: map-get($colors, 'green');
		transition-delay: 0;
	}

	/* Input ------------------- */

	input[type='range'] {
		appearance: none;
		-webkit-appearance: none;
		position: relative;
		vertical-align: middle;
		width: 100%;
		border-radius: 0px;
		border: none;
		border-top: 4px solid map-get($colors, 'white');
		border-bottom: 4px solid map-get($colors, 'white');
		background: map-get($colors, 'gray');
		box-shadow: none;
		pointer-events: none;
		height: 10px;
	}

	input[type='range']::-webkit-slider-thumb {
		appearance: none;
		-webkit-appearance: none;
		border-radius: 10px;
		width: 1px;
		height: 1px;
		border: 5px solid map-get($colors, 'green');
		background: map-get($colors, 'green');
		box-shadow: -100% 0 0 2px map-get($colors, 'green');
		pointer-events: none;
	}

	.status__icon {
		margin-right: 0 !important;
	}
}
