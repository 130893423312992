@import '../../scss/shared/componentsImport';

.Dashboard {
	.ColumnCard__image--padded .DefaultPortrait {
		max-width: 50px;
		margin-right: 10px;
	}

	.subhead {
		margin-bottom: 15px;
	}

	.group-title {
		font-weight: 700;
		margin-bottom: 20px;
		color: map-get($colors, 'black');
	}

	.ProfileCard {
		margin-bottom: 20px;
	}

	.card-grid {
		@include bp() {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 18px;
		}

		.ProfileCard + .ProfileCard {
			margin-top: 18px;

			@include bp() {
				margin-top: 0;
			}
		}
	}

	.container--fullwidth {
		.container--halfwidth {
			// max-width: 480px;
			margin: 5px auto;
		}
		@include bp($s) {
			display: flex;
			flex-wrap: wrap;
			.container--halfwidth {
				max-width: unset;
				margin-right: 0;
				margin-bottom: 5px;
				margin-left: 4.5%;
				width: 47%;

				&:nth-of-type(2n + 1) {
					margin-left: 0;
				}
			}
		}
	}

	&__my-progress-list > li + li {
		margin-top: 20px;
	}
}
