@use '~inter-ui/default' with ($inter-font-display: swap, $inter-font-path: '~inter-ui/Inter (web)');
@use '~inter-ui/variable' with ($inter-font-display: swap, $inter-font-path: '~inter-ui/Inter (web)');
@include default.all;
@include variable.all;

html {
	font-family: 'Inter', 'system-ui';
}

@supports (font-variation-settings: normal) {
	html {
		font-family: 'Inter var', 'system-ui';
	}
}

$font: 'Inter', sans-serif;

body {
	@include font-size(13px);
	line-height: 1.54;
	letter-spacing: 0.1px;
	color: map-get($colors, 'black');
	overflow-x: hidden;

	@include bp($s) {
		@include font-size(13px);
	}
}

p {
	margin-bottom: 13px;
}

a,
button.a,
button.btn--link {
	font-weight: 500;
	text-decoration: none;
	border-bottom: 2px solid transparent;
	@include transition(border 0.2s ease);

	&:hover {
		border-bottom: 2px solid;
	}
}

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
	font-weight: 600;
}

h5,
h6,
.h4,
.h5,
.h6 {
	font-weight: 400;
}

h1,
h2,
.h1,
.h2 {
	line-height: 1.3;
}

h1,
.h1 {
	@include font-size(22px);

	@include bp($s) {
		@include font-size(26px);
	}
}

h2,
.h2 {
	@include font-size(20px);

	@include bp($s) {
		@include font-size(24px);
	}
}

h3,
.h3 {
	@include font-size(18px);

	@include bp($s) {
		@include font-size(22px);
	}
}

h4,
.h4 {
	@include font-size(14px);

	@include bp($s) {
		@include font-size(18px);
	}
}

h5,
.h5 {
	@include font-size(12px);

	@include bp($s) {
		@include font-size(15px);
	}
}

strong {
	font-weight: bold;
}

em {
	font-style: italic;
}

small {
	@include font-size(9px);

	@include bp($s) {
		@include font-size(11px);
	}
}
