@import '../../scss/shared/componentsImport';

.Notification {
	@include font-size(13px);

	.notification {
		border-left: 5px solid transparent;
		border-bottom: 1px solid $skyTertiary;
		@include transition(all 0.2s ease-in-out);

		&:hover {
			background-color: map-get($colors, 'opaqueLightMaroon');
			border-left-width: 7px;

			.wrapper {
				padding-left: 18px;
			}
		}

		&--unread {
			background-color: map-get($colors, 'opaqueLightGreen') !important;
			border-left-color: map-get($colors, 'green');
		}

		.wrapper {
			@include transition(all 0.2s ease-in-out);
			padding: 20px;
			flex-flow: column;
		}

		&__header {
			width: 100%;
			display: flex;
			flex-flow: row;
			justify-content: space-between;
			align-items: flex-start;

			.title {
				@include font-size(13px);
				margin: 0 0 10px;
			}

			.time {
				@include font-size(11px);
			}
		}
	}
}
