@import '../../scss/shared/componentsImport';

.Nav {
	position: fixed;
	left: 0;
	bottom: 0;
	transform: translateX(-100%);
	color: map-get($colors, 'white');
	background-color: var(--theme-color);
	display: block;
	z-index: 1001;
	padding: 30px 10px;
	@include border-radius(0 12px 0 0);
	transition: 0.5s cubic-bezier(0.86, 0, 0.07, 1);

	// &.isViewingAs {
	// 	bottom: 100px;
	// }

	@include bp($m) {
		bottom: unset;
		left: 0;
		top: 75px;
		transform: translateX(0);
		@include border-radius(0 0 12px 0);
	}

	&__trigger {
		padding: 20px 22px;
		background-color: var(--theme-color);
		position: absolute;
		text-align: center;
		right: 0;
		bottom: 0;
		margin-left: auto;
		border: 0;
		display: block;
		line-height: 0;
		transition: 0.5s cubic-bezier(0.86, 0, 0.07, 1);
		transform: translateX(100%);
		@include border-radius(0 12px 12px 0);
		z-index: 1000;

		&:hover {
			cursor: pointer;
		}

		@include bp() {
			top: 0;
			bottom: unset;
			left: 0;
			transform: translateX(0) translateY(-80%);
			padding-left: 0;
			padding-right: 0;
			min-height: 60px;
			@include border-radius(0 12px 0 0);
		}

		svg {
			display: inline-block;
			vertical-align: middle;
			transition: 0.5s cubic-bezier(0.86, 0, 0.07, 1);

			@include bp() {
				position: absolute;
				right: 15px;
				top: 15px;
			}

			path {
				fill: #fff;
			}
		}
	}

	&--open {
		padding-right: 30px;
		transform: translateX(0);

		@include bp() {
			bottom: unset;
		}

		.Nav__links > li {
			.title {
				max-width: 1000px;
				opacity: 1;
				transform: translate3d(10px, 0, 0);
			}

			&.extra > button {
				border-radius: 8px;
				width: 100%;
				padding: 4px;
				min-width: 150px;
				background-color: #fff;
			}
		}
	}

	&__links > li {
		&.extra > button,
		> a {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			border-bottom: 0;
			color: var(--theme-secondary);
			position: relative;

			&.active {
				color: #fff;

				svg path {
					fill: #fff;
				}
			}

			.icon,
			.btn--icon {
				position: relative;
				display: block;
				width: 30px;
				height: 30px;
				z-index: 2;
			}

			svg {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate3d(-50%, -50%, 0);

				path {
					fill: var(--theme-secondary);
				}
			}
		}

		&.extra > button {
			border: 0;
			border-radius: 4px;
			padding: 0;
			background-color: transparent;

			&:hover {
				cursor: pointer;
			}

			svg path {
				fill: var(--theme-color);
			}

			.title {
				color: map-get($colors, 'black');
			}

			.icon {
				background-color: #fff;
				border-radius: 100%;
			}
		}

		.title {
			opacity: 0;
			z-index: 1;
			max-height: 30px;
			transform: translate3d(10px, 0, 0);
			transition: 0.5s cubic-bezier(0.86, 0, 0.07, 1);
			max-width: 1px;
			font-weight: 500px;
		}

		+ li {
			margin-top: 40px;
		}
	}

	// .nav-header {
	// 	position: absolute;
	// 	bottom: 0;
	// 	right: -80px;
	// 	display: flex;
	// 	align-items: flex-end;
	// 	justify-content: flex-end;
	// 	padding: 20px 20px 20px 30px;
	// 	background-color: $skyPrimary;
	// 	margin-bottom: 16px;

	// 	@include border-radius(0 12px 12px 0);
	// 	@include bp($m) {
	// 		position: relative;
	// 		bottom: unset;
	// 		right: unset;
	// 		padding: 0;
	// 	}
	// }

	// .nav-header,
	// nav {
	// 	.btn {
	// 		margin: 0;
	// 		background-color: $skyPrimary;

	// 		> svg path {
	// 			fill: $skySecondary;
	// 		}

	// 		&:hover {
	// 			background-color: $skyPrimary;
	// 		}

	// 		&.close,
	// 		&.hamburger {
	// 			> svg path {
	// 				fill: map-get($colors, 'white');
	// 			}
	// 		}
	// 	}

	// 	ul {
	// 		padding: 0;
	// 		list-style: none;
	// 		white-space: nowrap;

	// 		li {
	// 			+ li {
	// 				margin-top: 16px;
	// 			}

	// 			a {
	// 				display: flex;
	// 				align-items: center;
	// 				color: $skySecondary;
	// 				border: none;
	// 				@include transition(all 0.2s ease-in-out);
	// 				opacity: 0.7;

	// 				.btn {
	// 					padding: 10px 20px;
	// 				}

	// 				&:hover,
	// 				&.active {
	// 					color: map-get($colors, 'white');
	// 					opacity: 1;

	// 					.btn {
	// 						> svg path {
	// 							fill: map-get($colors, 'white');
	// 						}
	// 					}
	// 				}

	// 				&.cta {
	// 					opacity: 1;
	// 					color: $skyPrimary;
	// 					background: map-get($colors, 'white');
	// 					@include border-radius(10px);
	// 					margin: 20px 5px;
	// 					padding: 5px 20px 5px 5px;
	// 					transition: unset;

	// 					.btn {
	// 						color: $skyPrimary;
	// 						background: map-get($colors, 'white');
	// 						> svg path {
	// 							fill: $skyPrimary;
	// 						}
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}

	// 	a span {
	// 		margin-right: 10px;
	// 		display: inline-block;
	// 		max-width: 1500px;
	// 		min-width: 100%;
	// 		opacity: 1;
	// 		visibility: visible;
	// 		@include transition(max-width 0.2s ease-in-out);
	// 	}
	// }

	// &.collapsed {
	// 	max-width: 60px;
	// 	left: -50px;

	// 	@include bp($m) {
	// 		left: 0;
	// 	}
	// 	.ctas {
	// 		margin-top: 40px;
	// 	}

	// 	a {
	// 		&.cta {
	// 			background-color: unset;
	// 			margin: 20px 0;
	// 			padding: unset;
	// 		}
	// 		span {
	// 			margin-right: 0;
	// 			margin-left: -15px;
	// 			padding-right: 0;
	// 			max-width: 0;
	// 			overflow: hidden;
	// 			opacity: 0;
	// 			visibility: hidden;
	// 		}
	// 	}
	// }
}
