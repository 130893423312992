@import '../../scss/shared/componentsImport';

.CardThreeColumn {
	.card {
		display: flex;
		overflow: hidden;
		flex-flow: column;

		@include bp($s) {
			flex-flow: row;
		}

		.col {
			&--primary {
				@include bp($s) {
					width: 42%;
					border-right: 1px solid $skyTertiary;
				}
			}
			&--secondary {
				display: none;
				align-items: center;
				justify-content: center;

				@include bp($s) {
					display: block;
					width: 31%;
				}

				img {
					object-fit: cover;
				}

				svg {
					transform: scale(5);
					display: none;

					@include bp($s) {
						display: block;
					}

					path {
						fill: $skyPrimary;
					}
				}
			}
			&--tertiary {
				display: flex;
				align-items: center;
				justify-content: center;
				max-height: 300px;
				overflow: hidden;

				@include bp($s) {
					width: 26%;
					overflow: unset;
				}

				img {
					object-fit: cover;

					@include bp($s) {
					}
				}
			}
		}
	}

	&.profile {
		.col--secondary {
			display: flex;

			h5 {
				margin-bottom: 20px;
			}

			svg {
				transform: unset;
				display: block;
			}
		}
		.col--tertiary {
			max-height: unset;

			img {
				height: 100%;
			}
		}
		.profile__item {
			display: flex;
			align-items: center;
			margin-bottom: 10px;

			svg {
				margin-right: 10px;
			}
		}

		.profile__pic {
			width: 60px;
			height: 60px;
			@include border-radius(50%);
			margin-right: 15px;
			margin-bottom: 10px;
		}
	}

	.overview {
		.overview__item {
			display: flex;
			align-items: center;
			margin-bottom: 15px;

			svg {
				width: 20px;
				height: 20px;
				margin-right: 10px;
				transform: unset;
			}
		}
	}
}
