@import '../../scss/shared/componentsImport';

.EditProfile {
	&.edit-profile {
		.wrapper:not(:first-of-type) {
			padding: 0px 20px !important;
		}

		h5 {
			@include font-size(15px);
			font-weight: 600;
			margin-bottom: 20px;

			.btn-toggle {
				float: right;
				width: 12px;

				@include bp($s) {
					display: none;
				}

				svg {
					path {
						fill: map-get($colors, 'green');
					}
				}
			}
		}

		.btn--primary {
			padding: 10px 20px;
		}

		.card {
			max-height: 50px;
			min-height: unset;
			overflow: hidden;
			@include transition(all 0.3s ease-in-out);

			@include bp($s) {
				max-height: unset;
				overflow: unset;
			}

			&.expanded {
				max-height: 500vh;
			}

			&--details {
				.container {
					&--details {
						display: flex;
						flex-flow: column;
						margin-bottom: 0;
						@include bp($s) {
							flex-flow: row nowrap;
						}

						.col {
							@include border-radius(8px);
							overflow: hidden;
							&--image {
								width: 100%;
								position: relative;
								margin-bottom: 20px;
								@include bp($s) {
									width: 24%;
									margin: 0;
								}

								.edit-btn {
									position: absolute;
									top: 0px;
									right: 0px;
									width: 40px;
									height: 40px;
									transform: scale(0.75);
								}
							}
							&--inputs {
								width: 100%;
								@include bp($s) {
									width: 74%;
									margin-left: 2%;
									display: flex wrap;

									.form-field {
										width: 49%;
										display: inline-block;
										margin-left: 2%;

										&:nth-child(odd) {
											margin-left: 0;
										}
									}

									.cta {
										margin-top: 40px;
										transform: unset;
									}
								}
							}
						}
					}
				}
			}
			&.no-wrapper {
				> .wrapper {
					padding: 0 !important;
				}
				p {
					margin-bottom: 0;
				}
			}
			.wrapper {
				padding: 15px 20px 20px !important;
			}
		}
		.border-bottom {
			border-bottom: 1px solid $skyTertiary;
		}

		.toggle-section {
			h5 {
				float: left;
				margin-bottom: 15px;
			}
			.FormToggle {
				float: right;
			}
			p {
				clear: both;
			}
		}
		.Cta.right {
			margin: 20px 0;
			width: auto;

			@include bp($s) {
				margin: 10px 0 20px;
				float: right;
				// transform: translateY(-50px);
			}

			button.a {
				width: auto;
			}
		}

		.modal {
			.card {
				position: absolute;
				height: 100%;
				width: 100%;
				padding: 20px;
				margin: 0;

				@include bp($s) {
					position: relative;
					height: unset;
					padding: 2vw 3vw 0;
				}

				&::before {
					padding: 0;
				}

				&__container {
					position: relative;
					width: 100%;
					height: 100%;
					align-items: center;
					display: flex;
					justify-content: center;
					margin: 0 auto;
					overflow: hidden;

					@include bp($s) {
						width: 40vw;
						height: 40vw;
						margin: 2vw auto;
					}
				}

				.btn {
					margin-top: 10px;

					@include bp($s) {
						margin-top: 30px;
					}
				}

				.btn--zoom {
					display: block;
					position: absolute;
					top: 20px;
					right: 20px;
					width: 25px;
					min-width: unset;
					height: 25px;
					padding: 2px;
					margin: 0;
					z-index: 11;

					> svg {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate3d(-50%, -50%, 0) scale(0.9) !important;
					}
				}
			}
		}
	}
}
