@import '../../scss/shared/componentsImport';

.Header {
	width: 100vw;
	background-color: map-get($colors, 'white');
	position: relative;
	z-index: 100;

	.wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 8px;
		padding-bottom: 8px;

		@include bp($s) {
			padding-top: 14px;
			padding-bottom: 14px;
		}
	}

	a.logo {
		display: flex;
		align-items: center;
		border: none;
		margin-bottom: 0;
		max-width: 28vw;

		@include bp($s) {
			max-width: unset;
		}

		&:hover {
			border: none;
		}

		.tagline {
			margin-left: 20px;
			font-weight: 400;
			color: $earthPrimary !important;
			display: none;

			@include bp($s) {
				display: inline;
			}
		}
	}

	&__nav > ul {
		list-style: none;
		display: flex;
		flex-flow: row nowrap;

		li {
			position: relative;

			&.viewas-clients .card--dropdown p {
				padding: 20px;
				margin-bottom: 0;
			}

			&.viewas-clients .card--dropdown ul {
				border-top: 1px solid var(--theme-secondary);

				> li {
					margin-left: 0;

					button {
						padding: 20px;
						font-weight: bold;
						font-size: 15px;
						border: 0;
						background-color: transparent;
						display: block;
						width: 100%;
						text-align: left;

						&:hover {
							cursor: pointer;
						}
					}
				}
			}

			+ li {
				margin-left: 16px;
			}

			.Button--icon {
				width: 36px;
				height: 36px;
				background-color: transparent;
				border: 0;

				svg {
					transform: translate3d(-50%, -50%, 0);

					path {
						fill: var(--theme-color);
						transition: fill 0.25s ease-in-out;
					}
				}

				&:hover,
				&.active {
					background-color: var(--theme-color);

					svg path {
						fill: #fff;
					}
				}
			}
		}
	}
}
