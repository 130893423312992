@import '../../scss/shared/componentsImport';

.LessonIntro {
	.he--intro {
		@include bp() {
			max-width: 65%;
		}

		> * {
			margin-bottom: 40px;

			&.block--icon {
				padding-top: 0;
				padding-bottom: 0;
			}
		}
	}

	&__columns {
		@include bp() {
			display: flex;
			flex-flow: row wrap;
			margin: -40px 0 0 -40px;
		}

		> * {
			margin-bottom: 40px;

			@include bp() {
				margin-bottom: 0;
				padding: 40px 0 0 40px;
				width: 50%;
			}
		}
	}

	.he {
		padding: 15px;
		margin: 40px 0;

		@include bp($s) {
			padding: 0;
		}

		&__title {
			svg {
				margin-right: 20px;
			}
		}
	}

	.wysiwyg {
		ul {
			list-style: disc;
			padding-left: 1em;

			li + li {
				margin-top: 10px;
			}
		}
	}
}
