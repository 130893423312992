@import '../../scss/shared/componentsImport';

.Cta {
	.btn--link {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;

		&:hover {
			cursor: pointer;
		}
	}

	&.center .btn--link {
		justify-content: center;
	}

	&.right .btn--link {
		justify-content: center;
		@include bp() {
			justify-content: flex-end;
		}
	}

	a,
	button.a {
		margin: 0;
		@include font-size(15px);
		line-height: 1;
		font-weight: 700;
		border-bottom: 2px solid transparent;
		@include transition(border 0.2s ease);

		&:hover {
			border-bottom: 2px solid;
		}
	}

	.icon {
		margin-left: 10px;
		display: flex;
		align-items: center;

		svg {
			path {
				fill: map-get($colors, 'green');
			}
		}
	}
}
