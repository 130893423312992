.theme {
	&--earth {
		--theme-color: #{$earthPrimary};
		--theme-secondary: #{$earthSecondary};
		--theme-tertiary: #{$earthTertiary};
	}

	&--sky {
		--theme-color: #{$skyPrimary};
		--theme-secondary: #{$skySecondary};
		--theme-tertiary: #{$skyTertiary};

		h1,
		h2,
		h3,
		.h1,
		.h2,
		.h3 {
			color: $skyPrimary;
		}

		.bg {
			background-color: $skyTertiary;

			&--dark {
				background-color: $skyPrimary;
			}

			&--light {
				background-color: $skySecondary;
			}
		}

		.participant-section {
			padding: 30px;
			background: map-get($colors, 'lightGray');
			@include border-radius(20px);
			margin-bottom: 30px;
		}
	}
	&--earth,
	&--sky .participant-section {
		h1,
		h2,
		h3,
		h4,
		.h1,
		.h2,
		.h3,
		.h4 {
			color: $earthPrimary;
		}

		svg {
			&.primary {
				path {
					fill: $earthPrimary;
				}
			}
			&.secondary {
				path {
					fill: $earthSecondary;
				}
			}
		}

		.bg {
			background-color: $earthTertiary;

			&--dark {
				background-color: $earthPrimary !important;

				svg {
					path {
						fill: map-get($colors, 'white') !important;
					}
				}
			}

			&--light {
				background-color: $earthSecondary;
			}
		}

		.Cta {
			.cta {
				a {
					color: $earthPrimary;
				}
			}
		}

		// button,
		.btn {
			background-color: $earthPrimary;
			border: 2px solid $earthPrimary;

			&:hover {
				color: $earthPrimary;
				background-color: $earthSecondary;
			}

			&[disabled] {
				color: map-get($colors, 'darkGray');
				background-color: map-get($colors, 'gray');
				border-color: map-get($colors, 'gray');
			}

			&--secondary {
				color: $earthPrimary;
				background-color: transparent;

				&:hover {
					color: $earthPrimary;
					background-color: $earthTertiary;
				}

				svg {
					path {
						fill: $earthPrimary;
					}
				}

				&[disabled] {
					color: map-get($colors, 'darkGray');
					background-color: map-get($colors, 'white');
					border-color: map-get($colors, 'gray');

					svg {
						path {
							fill: map-get($colors, 'darkGray');
						}
					}
				}
			}

			&--icon {
				background: map-get($colors, 'white');
				border: none;

				&:hover {
					background: $earthSecondary;
				}

				svg {
					path {
						fill: $earthPrimary;
					}
				}
			}

			&--tab {
				color: $earthPrimary;
				background-color: $earthTertiary;
				border: 2px solid $earthTertiary;

				&.active {
					border: 2px solid $earthPrimary;
				}

				&:hover {
					background-color: $earthTertiary;
					border: 2px solid $earthSecondary;
					opacity: 0.8;
				}

				&[disabled] {
					color: map-get($colors, 'darkGray');
					background-color: map-get($colors, 'gray');
					border-color: map-get($colors, 'gray');
				}
			}

			&--zoom {
				background: $earthPrimary;

				&:hover {
					background: $earthPrimary;
					border-color: $earthPrimary;
				}
			}

			&--link {
				color: $earthPrimary;
				background-color: transparent;
				border: 0;

				&:hover {
					background-color: transparent;
				}
			}
		}

		textarea,
		select,
		input {
			border: 2px solid $earthTertiary;
			background-color: $earthTertiary;

			&:hover,
			&:focus {
				border: solid 2px rgba($earthPrimary, 0.45);
			}
		}

		input[type='radio'],
		input[type='checkbox'] {
			border: 2px solid $earthPrimary;
			background-color: transparent;

			&:checked {
				border: 2px solid $earthPrimary;
				background-color: $earthPrimary;
			}

			&:hover,
			&:focus {
				border: solid 2px rgba($earthPrimary, 0.45);
			}
		}

		.FormSelect {
			&.floating-label {
				> svg {
					path {
						fill: $earthPrimary;
					}
				}
			}
		}
		.nav {
			&--header {
				ul {
					li {
						button,
						a {
							> svg path {
								fill: $earthPrimary;
							}

							&.active,
							&:hover {
								background-color: $earthPrimary;

								> svg path {
									fill: map-get($colors, 'white');
								}
							}
						}
					}
				}
			}
		}

		// .Nav {
		// 	background-color: $earthPrimary;

		// 	.nav-header {
		// 		background-color: $earthPrimary;
		// 	}

		// 	.btn {
		// 		margin: 0;
		// 		background-color: $earthPrimary;

		// 		> svg path {
		// 			fill: $earthSecondary;
		// 		}

		// 		&:hover {
		// 			background-color: $earthPrimary;
		// 		}

		// 		&.close,
		// 		&.hamburger {
		// 			> svg path {
		// 				fill: map-get($colors, 'white');
		// 			}
		// 		}
		// 	}

		// 	ul {
		// 		li {
		// 			a {
		// 				color: $earthSecondary;

		// 				&:hover,
		// 				&.active {
		// 					color: map-get($colors, 'white');
		// 					opacity: 1;

		// 					.btn {
		// 						> svg path {
		// 							fill: map-get($colors, 'white');
		// 						}
		// 					}
		// 				}

		// 				&.cta {
		// 					color: $earthPrimary;
		// 					background: map-get($colors, 'white');
		// 					@include border-radius(10px);

		// 					.btn {
		// 						color: $earthPrimary;
		// 						background: map-get($colors, 'white');
		// 						> svg path {
		// 							fill: $earthPrimary;
		// 						}
		// 					}
		// 				}
		// 			}
		// 		}
		// 	}

		// 	&.collapsed {
		// 		a {
		// 			&.cta {
		// 				background-color: unset;
		// 			}
		// 		}
		// 	}
		// }

		.card {
			&.shaded {
				background-color: $earthTertiary;
			}

			.content-type-icon {
				svg {
					path {
						fill: $earthSecondary;
					}
				}
			}
		}

		.CardTwoColumn {
			.card {
				.col {
					&--secondary {
						svg {
							path {
								fill: $earthPrimary;
							}
						}
					}
				}
			}
		}
		.CardThreeColumn {
			.card {
				.col {
					&--primary {
						@include bp($s) {
							border-color: $earthTertiary;
						}
					}
					&--secondary {
						svg {
							path {
								fill: $earthPrimary;
							}
						}
					}
				}
			}
		}

		.ColumnCard {
			&__col {
				.lessons-list > li {
					a {
						color: $earthPrimary;
					}
				}
			}
		}

		.Header {
			&__nav {
				ul {
					li {
						button,
						a {
							> svg path {
								fill: $earthPrimary;
							}

							&.active,
							&:hover {
								background-color: $earthPrimary;

								> svg path {
									fill: map-get($colors, 'white');
								}
							}
						}
					}
				}
			}
		}

		.ImageCard {
			.image-zoom {
				.zoom-range {
					.icon-more,
					.icon-less {
						svg {
							path {
								fill: $earthPrimary;
							}
						}

						&:hover {
							opacity: 0.7;
						}
					}
					.customrange {
						background: $earthTertiary;
					}
					.customrange:focus::-webkit-slider-runnable-track {
						background: $earthPrimary;
					}
					.customrange::-webkit-slider-runnable-track {
						background: $earthPrimary !important;
					}
					.customrange::-webkit-slider-thumb {
						border: 2px solid $earthPrimary;
					}
				}
			}
		}

		.Notifications {
			.btn--load-more {
				color: $earthPrimary;
				background-color: map-get($colors, 'white');

				&:hover {
					color: $earthPrimary;
					background-color: map-get($colors, 'white');

					svg {
						path {
							fill: map-get($colors, 'green');
						}
					}
				}

				svg {
					path {
						fill: map-get($colors, 'green');
					}
				}
			}
		}

		.PageHeader {
			background-color: $earthTertiary;
		}

		.TableOfContents {
			ul {
				li {
					border-color: $earthTertiary;

					a {
						color: $earthPrimary;
					}
				}
			}
		}

		.edit-profile {
			.border-bottom {
				border-bottom: 1px solid $earthTertiary !important;
			}
		}
	}
}
