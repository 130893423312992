@import '../../scss/shared/componentsImport';

.DefaultPortrait {
	position: relative;
	width: 100%;
	height: 100%;

	&:after {
		content: '';
		display: block;
		padding-bottom: 30%;

		@include bp() {
			padding-bottom: 81%;
		}
	}

	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 20%;
		transform: translate3d(-50%, -50%, 0);
		height: auto;
		margin: 0;

		@include bp() {
			width: 50%;
		}
	}

	.theme--earth & {
		background-color: rgba($earthPrimary, 10%);

		.pathToColor {
			stroke: $earthPrimary;
		}
	}

	.theme--sky & {
		background-color: map-get($colors, 'bleachedBlue');

		.pathToColor {
			stroke: $skyPrimary;
		}
	}

	&--round {
		border-radius: 100%;

		&:after {
			padding-bottom: 100%;
		}

		svg {
			width: 50%;
		}
	}
}
