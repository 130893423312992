@import '../../scss/shared/componentsImport';

.PageHeader {
	background-color: $skyTertiary;
	padding: 40px 0 190px;
	margin-bottom: -150px;

	&--lesson-section {
		padding: 40px 0;
		margin-bottom: 48px;
	}

	&__top-bar {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;

		h1 {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 0;

			.icon {
				display: inline-block;
				width: 36px;
				height: 36px;
				margin: 0 12px 0 0;

				@include bp($s) {
					width: 48px;
					height: 48px;
				}

				svg {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate3d(-50%, -50%, 0);
				}
			}

			.subhead {
				font-size: 13px;
				font-weight: normal;
				margin-left: 18px;
				display: inline;
				vertical-align: bottom;
				transform: translateY(2px);
			}
		}

		.btn {
			margin: 0;
		}
	}
}
