$s: 768px;
$m: 1024px;
$l: 1200px;
$xl: 1440px;

$gap: 20px;

$colors: (
	'black': #1a1a1a,
	'white': #ffffff,
	'red': #ff0000,
	'maroon': #913537,
	'lightMaroon': #ddb2af,
	'bleachedMaroon': #f5ece6,
	'opaqueLightMaroon': rgba(221, 178, 175, 0.1),
	'darkBlue': #32325f,
	'blue': #666699,
	'lightBlue': #c2d4ec,
	'bleachedBlue': #eef2f7,
	'darkGreen': #1e5831,
	'green': #009774,
	'lightGreen': #c2d3a1,
	'opaqueLightGreen': rgba(194, 211, 161, 0.1),
	'rust': #a95100,
	'sierra': #d99250,
	'peach': #f8d3a4,
	'yellowWhite': #fff4d0,
	'orange': #ffac4d,
	'darkGray': #686868,
	'gray': #e4e4e4,
	'lightGray': #f7f7f6,
);

$gradients: (
	'default': linear-gradient(117.27deg, map-get($colors, 'pink') 0%, map-get($colors, 'lightPurple') 100%),
	'blue': linear-gradient(117.27deg, map-get($colors, 'purple') 0%, map-get($colors, 'blue') 100%),
	'teal': linear-gradient(117.27deg, map-get($colors, 'lightBlue') 0%, map-get($colors, 'teal') 100%),
	'red': linear-gradient(117.27deg, map-get($colors, 'orangeRed') 0%, map-get($colors, 'pink') 100%),
	'default-vertical': linear-gradient(180deg, map-get($colors, 'pink') 0%, map-get($colors, 'lightPurple') 100%),
	'blue-vertical': linear-gradient(180deg, map-get($colors, 'purple') 0%, map-get($colors, 'blue') 100%),
	'teal-vertical': linear-gradient(180deg, map-get($colors, 'lightBlue') 0%, map-get($colors, 'teal') 100%),
	'red-vertical': linear-gradient(180deg, map-get($colors, 'orangeRed') 0%, map-get($colors, 'pink') 100%),
);

$easeOutQuintic: cubic-bezier(0.23, 1, 0.32, 1);

// Theme colors - used based on user role - sky theme (blues) for Health Educator, earth theme (reds) for Participant
$skyPrimary: map-get($colors, 'darkBlue');
$skySecondary: map-get($colors, 'lightBlue');
$skyTertiary: map-get($colors, 'bleachedBlue');

$earthPrimary: map-get($colors, 'maroon');
$earthSecondary: map-get($colors, 'lightMaroon');
$earthTertiary: map-get($colors, 'bleachedMaroon');
