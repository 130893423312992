@import '../../scss/shared/componentsImport';

.Lesson {
	> .wrapper {
		@include bp() {
			display: flex;
			flex-flow: row nowrap;
		}
	}

	&__section {
		flex: 1;

		@include bp() {
			margin-right: 26px;
		}

		> .he + .participant-section {
			margin-top: 30px;
		}

		.participant-section .block__content ul > li:before {
			background-color: $earthPrimary;
		}

		.participant-section .block__content a[href$='.pdf'] {
			color: map-get($colors, 'black');
			border-color: $earthPrimary;
		}

		&__title,
		.he__title {
			margin-bottom: 30px;
		}

		.block + .block {
			margin-top: 30px;
		}

		.block--icon {
			padding-top: 0;
			padding-bottom: 0;

			.icon {
				top: 0;
			}

			.block__title {
				margin-top: 0;
			}

			.block__content > *:last-child {
				margin-bottom: 0;
			}
		}

		.block__content > .h5 {
			margin-bottom: 13px;
		}
	}

	&__aside {
		@include bp() {
			max-width: 286px;
		}
	}

	.block {
		&__injected-media {
			@include bp() {
				float: right;
				margin: 0 0 20px 20px;
				width: 50%;
			}

			// > * img {
			// 	max-width: none !important;
			// }
		}

		&__content {
			a[href$='.pdf'] {
				border-bottom: 2px solid;
			}

			ul,
			ol {
				margin-bottom: 1em;

				> li + li {
					margin-top: 8px;
				}

				li > ul > li + li {
					margin-top: 4px;
				}
			}

			ul > li {
				position: relative;

				&:before {
					content: '';
					display: inline-block;
					vertical-align: middle;
					width: 4px;
					height: 4px;
					background-color: var(--theme-color);
					border-radius: 4px;
					position: relative;
					margin-right: 14px;
					transform: translateY(-1px);
				}

				> ul,
				> ol {
					margin-left: 25px;
					margin-top: 4px;
					margin-bottom: 0;
				}
			}

			ol {
				counter-reset: ordered-counter;

				> li {
					counter-increment: ordered-counter;

					&:before {
						content: counter(ordered-counter) '.';
						color: $earthPrimary;
						margin-right: 0.5em;
					}

					> ul,
					> ol {
						margin-left: 25px;
						margin-top: 4px;
						margin-bottom: 0;
					}
				}
			}
		}

		.flexible-content--left .block__injected-media {
			@include bp() {
				float: left;
				margin: 0 20px 20px 0;
			}
		}

		.flexible-content--below {
			@include bp() {
				display: flex;
				flex-flow: column nowrap;

				.block__injected-media {
					float: none;
					width: 100%;
					order: 2;
				}

				.block__content {
					order: 1;
				}
			}
		}
	}
}
