.error {
	border-color: map-get($colors, 'red');

	&--message {
		color: map-get($colors, 'red');
		margin: -10px 0 10px 15px;
	}
}

form {
	textarea,
	select,
	input:not([type='range']) {
		font-size: 13px;
		font-weight: 400;
		color: map-get($colors, 'black');
		background-color: var(--theme-tertiary);
		width: 100%;
		padding: 15px;
		margin: 0 10px 10px 0;
		border: 2px solid;
		border-color: transparent;
		@include border-radius(8px);
		box-sizing: border-box;
		transition: all 0.2s linear;
		-webkit-appearance: none;

		&:hover,
		&:focus {
			border-color: var(--theme-secondary);
			outline: 0;
		}

		&.error {
			border-color: map-get($colors, 'red') !important;
		}
	}

	.theme--earth & textarea,
	.theme--earth & select,
	.theme--earth & input:not([type='range']) {
		background-color: $earthTertiary;
		border-color: $earthTertiary;

		&:hover,
		&:focus {
			border-color: rgba($earthPrimary, 0.45);
		}
	}

	textarea {
		min-height: 150px;
	}

	select {
		// background: url(./../../assets/icons/caret-down.svg) no-repeat 95% center $skySecondary;
	}

	input[type='radio'],
	input[type='checkbox'] {
		background: none;
		width: auto;
		padding: 10px;
		margin: 0;
		margin-right: 20px;
		border: 2px solid var(--theme-color);
		@include border-radius(50%);
		transition: all 0.2s linear;
		-webkit-appearance: none;

		&:checked {
			border: solid 2px var(--theme-color);
			outline: 0;
			background-color: var(--theme-color);
		}

		&:hover,
		&:focus {
			border: solid 2px rgba(var(--theme-color), 0.45);
			outline: 0;
			background-color: rgba(var(--theme-color), 0.45);
			cursor: pointer;
		}

		&.error {
			border-color: map-get($colors, 'red') !important;
		}
	}

	input[type='checkbox'] {
		@include border-radius(4px);
	}
}
// Gravity Forms
.gfield_label {
	visibility: hidden;
}

.gchoice {
	display: flex;
	align-items: center;
	flex-flow: row;
	margin: 0 0 15px;
}

.gform_button {
	display: none;
}
