@import '../../scss/shared/componentsImport';

.GravityForm {
	.gfield_label {
		visibility: hidden;
	}

	.gchoice {
		display: flex;
		align-items: center;
		flex-flow: row;
		margin: 0 0 15px;
	}

	.gform_button {
		display: none;
	}
	.gform-body {
		margin-bottom: 40px;
	}

	fieldset {
		border: none;
		display: block;
		margin: 0 0 20px;
		padding: 0;
	}

	.visible_label {
		.gfield_label {
			display: inline-block;
			visibility: visible;
			margin-bottom: 20px;
		}
	}

	.gform_fields {
		@include bp() {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;

			> * {
				width: 100%;

				&.gfield-column-10 {
					width: 83%;
				}

				&.gfield-column-8 {
					width: 66%;
				}

				&.gfield-column-6 {
					width: 48%;
				}

				&.gfield-column-4 {
					width: 33%;
				}

				&.gfield-column-2 {
					width: 16%;
				}
			}
		}
	}
}
