@import '../../scss/shared/componentsImport';

.Modules {
	&__ul > li + li {
		margin-top: 40px;
	}

	.ColumnCard .TableOfContents__list {
		margin-bottom: 20px;

		.icon.in-progress {
			background-color: #fff;
			border-color: map-get($colors, 'green');

			svg path {
				fill: map-get($colors, 'green');
			}

			&.complete {
				background-color: map-get($colors, 'green');
				border-color: map-get($colors, 'green');

				svg path {
					fill: #fff;
				}
			}
		}
	}
}
