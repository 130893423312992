.flexgrid {
	@include flexgrid();

	&__main {
		@include bp() {
			min-width: 65%;
			margin-right: 6px;
		}
	}
}

.modal {
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 4vw;
	z-index: 10;
	opacity: 1;
	@include transition(all 0.25s ease-in-out);

	@include bp($s) {
		padding: 12.5vw 25vw;
	}

	&.hide {
		z-index: -10;
		opacity: 0;
		transform: scale(0.0005);
	}

	.card {
		padding: 2vw 3vw 0;
		margin: 0;
		height: 100%;
		width: 100%;
		position: absolute;

		@include bp($s) {
			position: relative;
			height: unset;
		}

		&::before {
			padding: 0;
		}

		&__container {
			position: relative;
			width: 100%;
			height: 100%;
			align-items: center;
			display: flex;
			justify-content: center;
			margin: 0 auto;
			overflow: hidden;

			@include bp($s) {
				width: 40vw;
				height: 40vw;
				margin: 2vw auto;
			}
		}

		.btn {
			margin-top: 30px;
		}

		.btn--close {
			display: block;
			position: absolute;
			top: 20px;
			right: 20px;
			width: 25px;
			min-width: unset;
			height: 25px;
			padding: 2px;
			margin: 0;
			z-index: 11;

			> svg {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate3d(-50%, -50%, 0) scale(0.9) !important;
			}
		}
	}
}
