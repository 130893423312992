@import '../../scss/shared/componentsImport';

.FormSelect {
	&.floating-label {
		position: relative;
		margin-bottom: 10px;
		width: 100%;
		max-width: 286px;

		label {
			position: absolute;
			top: calc(50% - 10px);
			left: 0;
			opacity: 1;
			transition: all 0.3s ease;
			padding-left: 15px;
			font-size: 13px;
			pointer-events: none;
		}

		select {
			width: 100%;
			margin-left: auto;
			display: flex;
		}

		select:valid {
			padding: 15px 15px 7px 15px;
		}

		select:focus ~ label,
		select:valid ~ label {
			transform: translateY(-10px);
			opacity: 0.7;
			font-size: 10px;
		}

		> svg {
			position: absolute;
			top: calc(50% - 5px);
			right: 5%;
			pointer-events: none;

			path {
				fill: $skyPrimary;
			}
		}
	}
}
