@import '../../scss/shared/componentsImport';

.ProgressBar {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;

	&__bar {
		position: relative;
		height: 1px;
		background-color: #d8d8d8;
		width: 100%;
		margin-right: 20px;

		.track {
			position: absolute;
			top: -1px;
			left: 0;
			height: 3px;
			background-color: #c2d3a1;
		}

		.handle {
			position: absolute;
			top: -4px;
			left: 0;
			border-radius: 10px;
			width: 10px;
			height: 10px;
			background-color: #009774;
			transform: translateX(-50%);
		}
	}

	.complete-icon {
		display: block;
		background-color: #e4e4e4;
		width: 16px;
		height: 16px;
		border-radius: 16px;
		position: relative;

		svg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate3d(-50%, -50%, 0) scale(0.5);

			path {
				fill: #fff;
			}
		}
	}

	&--complete .complete-icon {
		background-color: #009774;
		margin-right: 5px;
	}

	&__portrait {
		position: relative;
		max-width: 50px;
		margin-right: 10px;
		width: 46px;
		height: 46px;

		.DefaultPortrait {
			border-radius: 100%;
		}

		img {
			width: 100%;
			object-fit: cover;
			object-position: center;
			border-radius: 100%;
		}

		.complete-icon {
			position: absolute;
			top: 0;
			right: 0;
			margin: 0;
		}
	}

	&__complete-text {
		h5 {
			font-weight: bold;
		}
	}
}
