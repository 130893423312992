@import '../../scss/shared/componentsImport';

.FormInput {
	&.floating-label {
		position: relative;
		margin-bottom: 10px;
		width: 100%;

		label {
			position: absolute;
			top: calc(50% - 7px);
			left: 0;
			opacity: 0;
			transition: all 0.3s ease;
			padding-left: 15px;
		}

		input {
			width: 100%;
			margin-left: auto;
			display: flex;
			padding: 11px 15px;
			background-color: var(--theme-tertiary);
		}

		input:not(:placeholder-shown) {
			padding: 15px 15px 7px 15px;
		}

		input:not(:placeholder-shown) + label {
			transform: translateY(-10px);
			opacity: 0.7;
			font-size: 10px;
		}

		button.visible_toggle {
			width: auto;
			display: inline-block;
			min-width: unset;
			padding: 5px 5px 0;
			background: transparent;
			border: none;
			position: absolute;
			top: 10px;
			right: 0px;
		}
	}
}
