@import '../../scss/shared/componentsImport';

.LessonOrder {
	.PageHeader {
		padding-top: 40px;
		padding-bottom: 30px;
		margin-bottom: 30px;
	}

	> .wrapper {
		@include bp() {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
		}
	}

	&__groups {
		@include bp() {
			width: 65%;
		}

		.lessons-group {
			margin-bottom: 50px;
		}

		.SortableLessonCard + .SortableLessonCard {
			margin-top: 10px;
		}
	}

	&__actions {
		display: none;

		@include bp() {
			display: block;
			flex: 1;
			margin-left: 26px;
			max-width: 286px;
		}

		.Button {
			width: 100%;
			font-weight: bold;
			letter-spacing: -0.005em;
		    
			+ .Button {
				margin-top: 12px;
			}
		}
	}
}
