@import '../../scss/shared/componentsImport';

.ImageCard {
	position: relative;

	.card--image {
		padding: 20px;

		.btn--zoom {
			position: absolute;
			top: 10px;
			right: 10px;
			width: 25px;
			height: 25px;
			margin: 0;
			// pointer-events: none;

			> svg {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate3d(-50%, -50%, 0) scale(0.7);
			}
		}

		&__container > img {
			transition: 0.35s $easeOutQuintic;
		}
	}

	.modal {
		position: fixed;
		width: 100vw;
		height: 100vh;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 4vw;
		z-index: 10;
		opacity: 1;
		@include transition(all 0.25s ease-in-out);

		@include bp($s) {
			padding: 12.5vw;
		}

		&.hide {
			z-index: -10;
			opacity: 0;
			transform: scale(0.5);
		}

		.card--image {
			padding: 1vw 5vw;
			margin: 0;
			height: 100%;
			width: 100%;
			position: absolute;

			@include bp($s) {
				position: relative;
				height: unset;
			}

			&::before {
				padding: 0;
			}

			&__container {
				position: relative;
				width: 100%;
				height: 100%;
				align-items: center;
				display: flex;
				justify-content: center;
				margin: 0 auto;
				overflow: hidden;

				@include bp($s) {
					width: 40vw;
					height: 40vw;
					margin: 2vw auto;
				}
			}

			img {
				position: relative;
				transform: scale(1);
				height: auto;

				@include bp($s) {
					position: absolute;
					height: 100%;
				}
			}

			.btn--zoom {
				display: block;
				position: absolute;
				top: 20px;
				right: 20px;
				width: 25px;
				min-width: unset;
				height: 25px;
				padding: 2px;
				margin: 0;
				z-index: 11;

				> svg {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate3d(-50%, -50%, 0) scale(0.9) !important;
				}
			}
		}
	}

	.image-zoom {
		position: absolute;
		right: 0px;
		bottom: 50%;
		display: none;

		@include bp($s) {
			display: block;
		}

		.zoom-range {
			position: relative;
			transform: translateX(50px);

			.icon-more {
				position: absolute;
				transform: translate(105px, -85px);
				z-index: 1;
			}

			.icon-less {
				position: absolute;
				transform: translate(105px, 95px);
				z-index: 2;
			}

			.customrange {
				width: 100%;
				height: 33px;
				padding: 0px 50px;
				border: none;
				border-radius: 22px;
				-webkit-appearance: none;
				background: $skyTertiary;
				margin: 0;
				transform: rotate(-90deg);
			}
			.customrange::-moz-focus-outer {
				border: 0;
			}
			.customrange:focus {
				outline: 0;
			}
			.customrange:focus::-webkit-slider-runnable-track {
				background: #fbfbfc;
			}
			.customrange:focus::-ms-fill-lower {
				background: #eceff1;
			}
			.customrange:focus::-ms-fill-upper {
				background: #fbfbfc;
			}
			.customrange::-webkit-slider-runnable-track {
				cursor: default;
				height: 1px;
				transition: all 0.2s ease;
				width: 100%;
				box-shadow: none;
				background: $skySecondary !important;
				border: none;
				border-radius: 15px;
			}
			.customrange::-webkit-slider-thumb {
				box-shadow: none;
				background: #fff;
				border: 2px solid $skyPrimary;
				border-radius: 12px;
				box-sizing: border-box;
				cursor: default;
				height: 14px;
				width: 14px;
				-webkit-appearance: none;
				margin-top: -7px;
			}
			.customrange::-moz-range-track {
				box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2), 0 0 1px rgba(13, 13, 13, 0.2);
				cursor: default;
				height: 8px;
				transition: all 0.2s ease;
				width: 100%;
				background: #eceff1;
				border: 2px solid #cfd8dc;
				border-radius: 15px;
				height: 4px;
			}
			.customrange::-moz-range-thumb {
				box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2), 0 0 4px rgba(13, 13, 13, 0.2);
				background: #607d8b;
				border: 2px solid #eceff1;
				border-radius: 12px;
				box-sizing: border-box;
				cursor: default;
				height: 24px;
				width: 24px;
			}
			.customrange::-ms-track {
				cursor: default;
				height: 8px;
				transition: all 0.2s ease;
				width: 100%;
				background: transparent;
				border-color: transparent;
				border-width: 12px 0;
				color: transparent;
			}
			.customrange::-ms-fill-lower {
				box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2), 0 0 1px rgba(13, 13, 13, 0.2);
				background: #dde3e6;
				border: 2px solid #cfd8dc;
				border-radius: 10px;
			}
			.customrange::-ms-fill-upper {
				box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2), 0 0 1px rgba(13, 13, 13, 0.2);
				background: #eceff1;
				border: 2px solid #cfd8dc;
				border-radius: 10px;
			}
			.customrange::-ms-thumb {
				box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2), 0 0 4px rgba(13, 13, 13, 0.2);
				background: #607d8b;
				border: 2px solid #eceff1;
				border-radius: 12px;
				box-sizing: border-box;
				cursor: default;
				height: 24px;
				width: 24px;
				margin-top: 2px;
			}
			.customrange:disabled::-webkit-slider-thumb,
			.customrange:disabled::-moz-range-thumb,
			.customrange:disabled::-ms-thumb,
			.customrange:disabled::-webkit-slider-runnable-track,
			.customrange:disabled::-ms-fill-lower,
			.customrange:disabled::-ms-fill-upper {
				cursor: not-allowed;
			}
		}
	}
}
