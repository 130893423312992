@import '../../scss/shared/componentsImport';

.ImageZoom {
	position: relative;
	overflow: hidden;
	border-radius: 12px;

	&:before {
		content: '';
		display: block;
		padding-bottom: 100%;
	}

	&__modal-trigger.Button {
		position: absolute;
		right: 12px;
		top: 12px;
		z-index: 2;
	}

	img {
		object-fit: cover;
		object-position: center;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
	}
}
