@use 'sass:math';
@import '../../scss/shared/componentsImport';

.SimpleForm {
	@include bp() {
		display: flex;
		flex-flow: row wrap;
		margin: -40px 0 0 -40px;
		width: 100%;
	}

	&__field {
		flex: 0 0 100%;
		margin-bottom: 40px;

		@include bp() {
			margin-bottom: 0;
			padding: 40px 0 0 40px;
		}

		&--2 {
			@include bp() {
				flex: 0 0 percentage(math.div(1, 6));
			}
		}

		&--4 {
			@include bp() {
				flex: 0 0 percentage(math.div(1, 3));
			}
		}

		&--6 {
			@include bp() {
				flex: 0 0 percentage(math.div(1, 2));
			}
		}

		textarea {
			margin: 0;
		}

		h4 {
			font-weight: 500;
		}

		&__radio,
		&__checkbox {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;

			+ .SimpleForm__field__checkbox,
			+ .SimpleForm__field__radio {
				margin-top: 15px;
			}
		}
	}
}
