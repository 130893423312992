@import '../../scss/shared/componentsImport';

.Educator {
	.ColumnCard {
		margin-bottom: 40px;
	}

	.h5 {
		font-weight: bold;
		margin-bottom: 20px;
	}

	&__clients-grid {
		margin-bottom: 40px;

		.card {
			margin-bottom: 0;
		}

		@include bp() {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 40px;
		}

		.Card + .Card {
			margin-top: 20px;

			@include bp() {
				margin-top: 0;
			}
		}
	}
}
