@import '../../scss/shared/componentsImport';

.Client {
	.ColumnCard {
		margin-bottom: 40px;

		.btn {
			width: 100%;
		}
	}

	.ColumnCard > .ColumnCard__col:nth-of-type(3) {
		display: block;
	}

	.container--btn .btn {
		margin: 0;
	}

	.h5 {
		font-weight: bold;
		margin-bottom: 20px;
	}

	&__progress-grid {
		margin-bottom: 40px;

		@include bp() {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 20px;
		}
	}
}
