// button,
.btn {
	display: flex;
	justify-content: center;
	align-items: center;
	@include font-size(13px);
	font-weight: 500;
	text-align: center;
	text-decoration: none;
	color: map-get($colors, 'white');
	background-color: $skyPrimary;
	border: 2px solid $skyPrimary;
	@include border-radius(8px);
	padding: 10px;
	min-width: 100px;
	max-width: 280px;
	margin: 0 auto 10px;
	@include transition(all 0.2s ease-in-out);
	cursor: pointer;

	@include bp($s) {
		display: inline-flex;
		@include font-size(15px);
		min-width: 138px;
		padding: 13px 25px;
		margin: 0 8px 10px 0;
	}

	&:hover {
		color: $skyPrimary;
		background-color: $skySecondary;
	}

	&[disabled] {
		color: map-get($colors, 'darkGray');
		background-color: map-get($colors, 'gray');
		border-color: map-get($colors, 'gray');
		cursor: not-allowed;
	}

	&--link {
		background-color: transparent;
		color: $skyPrimary;
		border: 0;
		margin: 0;
		padding: 0;
		min-width: 0;
		max-width: none;
		border-radius: unset;

		&:hover {
			background-color: transparent;
		}
	}

	&--wide {
		min-width: 250px;
		width: 100%;
		max-width: 320px;

		@include bp($s) {
			width: unset;
		}
	}

	&--secondary {
		color: $skyPrimary;
		background-color: transparent;

		&:hover {
			color: $skyPrimary;
			background-color: $skyTertiary;
		}

		svg {
			width: 16px;
			height: 18px;
			margin-left: 10px;

			path {
				fill: $skyPrimary;
			}
		}

		&[disabled] {
			color: map-get($colors, 'darkGray');
			background-color: transparent;
			border-color: map-get($colors, 'gray');
			cursor: not-allowed;

			svg {
				path {
					fill: map-get($colors, 'darkGray');
				}
			}
		}
	}

	&--icon {
		width: 40px;
		height: 40px;
		min-width: unset;
		padding: 10px;
		border: none;
		@include border-radius(50%);
		background: map-get($colors, 'white');
		position: relative;
		margin: 0 auto 10px;

		@include bp($s) {
			margin: 0 10px -15px 0;
		}

		&:hover {
			background: $skySecondary;
		}

		> svg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate3d(-50%, -50%, 0);

			path {
				fill: $skyPrimary;
			}
		}
	}

	&--tab {
		color: $skyPrimary;
		background-color: $skyTertiary;
		border: 2px solid $skyTertiary;

		&.active {
			border: 2px solid $skyPrimary;
		}

		&:hover {
			background-color: $skyTertiary;
			border: 2px solid $skySecondary;
			opacity: 0.8;
		}

		&[disabled] {
			color: map-get($colors, 'darkGray');
			background-color: map-get($colors, 'gray');
			border-color: map-get($colors, 'gray');
			cursor: not-allowed;
		}
	}

	&--zoom {
		width: 25px;
		height: 25px;
		min-width: unset;
		padding: 0;
		border: none;
		@include border-radius(50%);
		background: $skyPrimary;

		@include bp($s) {
			margin: 0 10px -15px 0;
		}

		&:hover {
			background: $skySecondary;
		}

		> svg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate3d(-50%, -50%, 0);

			path {
				fill: map-get($colors, 'white');
			}
		}
	}

	&--load-more {
		&:hover {
			background-color: transparent;
			color: initial;
		}
	}
}
