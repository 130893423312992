@import '../../scss/shared/componentsImport';

.ImageUpload {
	position: relative;
	overflow: hidden;
	border-radius: 12px;

	&__edit-btn {
		margin: 0 !important;
		position: absolute !important;
		top: 10px;
		right: 10px;
		z-index: 100;
	}

	input[type='file'] {
		padding: 0;
		border: 0;
		margin: 0;
		background-color: transparent;
		appearance: none;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		opacity: 0;
		transform: translate3d(-50%, -50%, 0);
		z-index: 100;

		&:hover {
			cursor: pointer;
		}
	}

	img {
		object-fit: cover;
		height: 100%;
		width: 100%;
	}
}
