@import '../../scss/shared/componentsImport';

.MiniProgressCard {
	padding: 15px;
	position: relative;
	border-radius: 12px;
	box-shadow: 0 3px 36px -10px rgb(0 0 0 / 0.22);
	transition: box-shadow 0.2s ease-in-out;
	background-color: #fff;

	&:hover {
		box-shadow: 0 15px 36px 0px rgb(26 26 26 / 0.15);
	}

	> svg {
		position: absolute;
		bottom: 15px;
		right: 15px;
	}

	.ProgressBar {
		margin-bottom: 20px;
	}

	&__module-title {
		font-size: 0.8125rem;
	}

	&__lesson-title {
		font-size: 0.9375rem;
		font-weight: 700;
		margin: 0;
	}
}
